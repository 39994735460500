<template>
  <div class="caseVue serviceVue">
    <header class="header">
      <h3 class="animated fadeInDown">专业服务</h3>
      <h5 class="animated fadeInUp">
        从咨询规划、研发集成到运维运营的端到端专业服务
      </h5>
    </header>
    <main>
      <!-- 咨询服务 -->
      <b-container fluid="xl" class="mould">
        <b-row class="mb-4">
          <b-col md="12">
            <h3>咨询服务</h3>
            <h6>
              为客户提供从企业战略到组织，从营销、服务到客户体验的管理咨询服务，帮助客户提升战略管理能力，增强竞争力，支撑企业转型变革。
            </h6>
          </b-col>
          <b-col md="4"> </b-col>
        </b-row>
      </b-container>
      <!-- 集成服务 -->
      <b-container fluid="xl" class="mould">
        <b-row class="mb-4">
          <b-col md="12">
            <h3>集成服务</h3>
          </b-col>
          <b-col
            md="4"
            v-for="item in integrate"
            :key="item.title"
            class="integrate"
          >
            <b-card
              :title="item.title"
              :img-src="item.img"
              :img-alt="item.title"
              img-top
              tag="article"
              class="mb-2"
            >
              <b-card-text>
                {{ item.content }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <section class="department mould">
        <h3>四大部门多位一体</h3>
        <h6>
          广东壹公里从技术到服务全面把控，为平台客户带来全方位升级体验，
          解决平台客户的售前售后多重忧虑。
        </h6>
        <b-container fluid="xl">
          <!-- <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, i) in department"
                :key="i"
              >
                <div>
                  <i :class="`iconfont ${item.icon}`"></i>
                  <h5>{{ item.title }}</h5>
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div> -->
          <swiper
            v-if="swiperIF"
            :auto-update="true"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(item, i) in department"
              :key="i"
              class="swiper-slide"
              ><i :class="`iconfont ${item.icon}`"></i>
              <h5>{{ item.title }}</h5>
              <p>{{ item.content }}</p></swiper-slide
            >
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </b-container>
      </section>
    </main>
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/swiper.scss";
// import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { WOW } from "wowjs";
var mySwiper;
export default {
  //import引入的组件
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive,
  },
  // props: ["department"],
  data() {
    return {
      swiperIF: false,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
      department: [
        {
          icon: "icon-shangwu bule",
          title: "商务部经理",
          content:
            "同时具备战略思考能力、统筹管理能力、沟通谈判能力、数据分析和决策能力。并拥有十多项大型信息化项目的操作经验，凭借自己强有力的实战经验，带领着一批精兵团队。更是因为他的诚信、诚恳、诚勤，获得了众多客户与合作伙伴的认可。",
        },
        {
          icon: "icon-yusuanpingheng yellow",
          title: "预算部经理",
          content:
            "一位在本行从事了15年的电子信息高级工程师，经历过无数项信息化管理统筹项目经验。掌握国家及工程所在地的工程造价政策、文件和定额标准，及时了解掌握工程造价变化情况信息，收集掌握与工程造价、工程预、决算有关的技术资料和文件资料，实施工程预算动态管理。",
        },
        {
          icon: "icon-jishutong pink",

          title: "技术部经理",
          content:
            "拥有弱电高级工程师、智能建筑弱电系统项目管理师的头衔，并独立掌管着一支强大的技术部团队，多年从事智能信息化管理项目经验，对客户做到有求必应，越是困难，越是迎难而上，在项目中做到完美统筹。",
        },
        {
          icon: "icon-ruanjian- bule",
          title: "软件部经理",
          content:
            "作为一名系统架构师，打造了一支具备多项技能的精英团队，精通Java、Python等编程语言，熟悉分布式微服务主流框架，精通DB2、MySQL数据库体系结构， 熟练掌握DB2 HADR、Mysql主从复制技术、MHA等技术，有5年的智慧城市以及大型系统开发项目的总体规划、方案设计以及实施团队管理经验。",
        },
      ],
      integrate: [
        {
          title: "端到端软硬件集成",
          content:
            "利用广东壹公里端到端的系统集成能力，为客户提供涵盖软件、硬件、网络以及其它相关资源的交钥匙工程。",
          img: require("../assets/images/anli/智慧运维系统.jpg"),
        },
        {
          title: "中立技术标准",
          content:
            "秉持技术中立原则，广东壹公里的产品支持与第三方产品对接甚至融合，保护客户以往投资，帮助客户进行成本管控，并与客户共同制定企业技术标准。",
          img: require("../assets/images/anli/bz1.jpg"),
        },
        {
          title: "广泛战略协同",
          content:
            "我们与华为、海康、联通等公司进行合作的同时积极开展业务层面的协同。我们还与阿里云等合作伙伴在相关领域开展深度合作，强强联合，协同发展。",
          img: require("../assets/images/anli/xt.jpg"),
        },
      ],
      screenWidth: document.body.clientWidth,
      slidesPerViews: 3,
    };
  },
  //方法集合
  methods: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    if (this.screenWidth < 768) {
      this.slidesPerViews = 1;
    } else {
      this.slidesPerViews = 3;
    }
    this.swiperOptions.slidesPerView = this.slidesPerViews;
    this.swiperIF = true
    // console.log(this.swiperOptions.slidesPerView)
    // this.swiper.slideTo(3, 1000, false);
    // mySwiper = new Swiper(".swiper-container", {
    //   slidesPerView: this.slidesPerView,
    //   // centeredSlides: true,
    //   slidesOffsetBefore: 100,
    //   // spaceBetween : '10%', //按container的百分比
    //   grabCursor: true,
    //   slidesPerGroup: 3,
    //   slidesPerGroupSkip: 4,
    //   spaceBetween: 20,
    //   // updateOnWindowResize: true,
    // });
  },
  computed: {
    swiper(e) {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>
<style lang="scss">
//@import url(); 引入公共css类
@import url("../assets/font/iconfont.css");
.serviceVue {
  .mould {
    margin: 90px auto;
    h3 {
      text-align: center;
      margin-bottom: 50px;
      font-weight: 800;
    }
    h6 {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.2rem;
    }
    .integrate {
      margin-bottom: 20px;
      .card {
        background: rgba(249, 249, 249, 1);
        border: none;
        height: 100%;
        .card-img,
        .card-img-top {
          height: 60%;
        }
        h4 {
          font-size: 1.2rem;
          font-weight: 800;
        }
        p {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
.department {
  .swiper-slide {
    // width: 360px !important;
    height: initial;

    // margin: 10px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fffaf0;
    .iconfont {
      font-size: 10rem;
    }
    h5 {
      font-weight: bolder;
      margin-bottom: 20px;
    }
    p {
      text-indent: 2rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.bule {
  color: #007bff;
}
.yellow {
  color: #ffc107;
}
.pink {
  color: #e83e8c;
}
</style>